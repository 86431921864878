import Setting from "../../setting.js";
import CVDTable from "@/components/CVDTable.vue";
import ExcelExport from "@/components/ExcelExport.vue";
import { useAppStore } from "../stores/main";

export default {
  name: "NgrgSurveyForm",

  components: {
    CVDTable,
    ExcelExport,
  },
  data: () => ({
    store: useAppStore(),
    loading: false,
    items: [],
    exportItems: [],

    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },

      {
        text: "CVD Form",
        disabled: false,
      },
    ],
    headers: [
      {
        text: "Actions",
        align: "start",
        sortable: false,
        value: "actions",
      },
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "data.email",
      },
      {
        text: "Create Date",
        align: "end",
        sortable: true,
        value: "createdDate",
      },
      {
        text: "Name",
        align: "end",
        sortable: true,
        value: "data.name",
      },
      {
        text: "Organization",
        align: "end",
        sortable: true,
        value: "data.organization",
      },
      {
        text: "Phone",
        align: "end",
        sortable: true,
        value: "data.phone",
      },
      {
        text: "Title of the vulnerability",
        align: "end",
        sortable: true,
        value: "data.vulnerability",
      },
      {
        text: "Impacted O-RAN specification(s) or open source code",
        align: "end",
        sortable: true,
        value: "data.impact",
      },
      {
        text: "Details of the vulnerability",
        align: "end",
        sortable: true,
        value: "data.vulnerabilityDetails",
      },
      {
        text: "Steps and circumstances to reproduce the vulnerability",
        align: "end",
        sortable: true,
        value: "data.vulnerabilitySteps",
      },
      {
        text: "Threat(s) caused by the vulnerability",
        align: "end",
        sortable: true,
        value: "data.vulnerabilityThreat",
      },
      {
        text: "How do you assess severity of the vulnerability  ",
        align: "end",
        sortable: true,
        value: "data.vulnerabilitySeverity",
      },
      {
        text: "When (approximately) was the vulnerability discovered",
        align: "end",
        sortable: true,
        value: "data.vulnerabilityDate",
      },
      {
        // prettier-ignore
        text:
          "Is the vulnerability present in current version of O-RAN specification(s)",
        align: "end",
        sortable: true,
        value: "data.vulnerabilityCurrent",
      },
      {
        // prettier-ignore
        text:
          "Do you believe the vulnerability is currently exploited",
        align: "end",
        sortable: true,
        value: "data.vulnerabilityExploited",
      },
      {
        // prettier-ignore
        text:
          "Please provide reasoning",
        align: "end",
        sortable: true,
        value: "data.reasoning",
      },
      {
        // prettier-ignore
        text:
          "Have you reported this vulnerability to other organizations",
        align: "end",
        sortable: true,
        value: "data.vulnerabilityReported",
      },
      {
        text: "Agreement to legal information and disclaimers",
        align: "end",
        sortable: true,
        value: "data.agreementLegal",
      },
      {
        text: "Agreement to processing of personal data",
        align: "end",
        sortable: true,
        value: "data.agreementData",
      },
      {
        text: "Attachments",
        align: "end",
        sortable: true,
        value: "data.files",
      },
    ],
    fields: {
      Email: "Email",
      CreatedDate: "CreatedDate",
      Name: "Name",
      Organization: "Organization",
      Phone: "Phone",
      "Title of the vulnerability": "TitleVulnerability",
      "Impacted O-RAN specification(s) or open source code":
        "ImpactedSpecifications",
      "Details of the vulnerability": "VulnerabilityDetails",

      "Steps and circumstances to reproduce the vulnerability":
        "VulnerabilitySteps",
      "Threat(s) caused by the vulnerability": "VulnerabilityThreats",
      "How do you assess severity of the vulnerability":
        "VulnerabilitySeverity",

      "When (approximately) was the vulnerability discovered":
        "VulnerabilityDiscovered",

      "Is the vulnerability present in current version of O-RAN specification(s)":
        "VulnerabilityPresent",

      "Do you believe the vulnerability is currently exploited":
        "VulnerabilityExploited",
      "Please provide reasoning": "Reasoning",

      "Have you reported this vulnerability to other organizations":
        "VulnerabilityReport",
      "Agreement to legal information and disclaimers": "AgreementLegal",
      "Agreement to processing of personal data": "AgreementData",
      Attachments: "Attachments",
    },
  }),
  async mounted() {
    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
  async created() {
    this.loading = true;
    const result = await this.getData();

    this.items = result.data.items;

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      /*
      console.warn({
        item: item,
      });
      */

      this.exportItems.push({
        Id: item.id,
        Email: item.data.email,
        Name: item.data.name
          ? item.data.name.replaceAll(",", ";").replaceAll(/\n/g, "; ").trim()
          : "",
        CreatedDate: item.createdDate,
        UpdatedDate: item.lastUpdateDate,
        Organization: item.data.organization
          ? item.data.organization
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        Phone: item.data.phone
          ? item.data.phone.replaceAll(",", ";").replaceAll(/\n/g, "; ").trim()
          : "",

        TitleVulnerability: item.data.vulnerability
          ? item.data.vulnerability
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        ImpactedSpecifications: item.data.impact
          ? item.data.impact.replaceAll(",", ";").replaceAll(/\n/g, "; ").trim()
          : "",
        VulnerabilityDetails: item.data.vulnerabilityDetails
          ? item.data.vulnerabilityDetails
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        VulnerabilitySteps: item.data.vulnerabilitySteps
          ? item.data.vulnerabilitySteps
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        VulnerabilityThreats: item.data.vulnerabilityThreat
          ? item.data.vulnerabilityThreat
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        VulnerabilitySeverity: item.data.vulnerabilitySeverity
          ? item.data.vulnerabilitySeverity
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        VulnerabilityDiscovered: item.data.vulnerabilityDate,
        VulnerabilityPresent: item.data.vulnerabilityCurrent
          ? item.data.vulnerabilityCurrent
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        VulnerabilityExploited: item.data.vulnerabilityExploited
          ? item.data.vulnerabilityExploited
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        Reasoning: item.data.reasoning
          ? item.data.reasoning
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        VulnerabilityReport: item.data.vulnerabilityReported
          ? item.data.vulnerabilityReported
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        AgreementLegal: item.data.agreementLegal
          ? item.data.agreementLegal
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        AgreementData: item.data.agreementData
          ? item.data.agreementData
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        Attachments: item.data.files
          ? item.data.files
              .map((item) => item.name)
              .join("; ")
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
      });
    }

    /*
    console.warn({
      items: this.items,
      exportItems: this.exportItems,
    });
    */

    this.loading = false;
  },
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    async getData() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "CVDFormContoller/GetAll",

        {
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
  },
};
