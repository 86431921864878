import ExcelExport from "@/components/ExcelExport.vue";

export default {
  name: "SmartTable",
  props: {
    headers: Array,
    fields: Object,
    items: Array,
    exportItems: Array,
    loading: Boolean,
    documents: Array,
  },
  components: {
    ExcelExport,
  },

  data() {
    return {
      search: "",
      dialog: false,
      menu: false,
      modal: false,
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    onExport(item) {
      let x = [];
      let result = this.exportItems.find((x) => x.Id === item.id);
      x.push(result);

      console.warn({
        onExport: item,
        exportItems: this.exportItems,
        x: x,
        find: this.exportItems.find((x) => x.Id === item.id),
      });

      return x;
    },
  },
};
